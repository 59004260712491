/* eslint react/destructuring-assignment: 0 */
import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { Global, css } from "@emotion/core"
import { SEO, Wrapper, Header } from "../components"
import { LocaleContext } from "../components/Layout"

const Hero = styled.header`
  background-color: ${props => props.theme.colors.bg};
  padding-top: 1rem;
  padding-bottom: 4rem;

  h1 {
    color: ${props => props.theme.colors.black};
  }
`

const LocaleSwitcherStyle = theme => css`
  [data-name="locale-switcher"] {
    color: ${theme.colors.greyBlue};
    a {
      color: white;
    }
  }
`

const CatWrapper = Wrapper.withComponent("main")

const Categories = ({
  location,
  data: { categories },
  pageContext: { locale },
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  return (
    <>
      <Global styles={LocaleSwitcherStyle} />
      <SEO
        title={`${i18n.about} | ${i18n.defaultTitleAlt}`}
        pathname={location.pathname}
        locale={locale}
      />
      <Hero>
        <Wrapper>
          <Header invert />
        </Wrapper>
      </Hero>
      <CatWrapper>
        <h1>{i18n.about}</h1>
        <h2 style={{ marginTop: "4rem" }}>Get to know about me!</h2>
        <p>
          Hi! I am a frontend developer and e-commerce consultant with a strong
          affinity to art and design.
        </p>
        <p>
          Until the end of 2018, I was building up the sustainable fashion label
          and e-commerce company ThokkThokk. After the sale of ThokkThokk, I am
          now engaged in the development of creative web solutions. In doing so,
          I like it to break confessions, the status quo and finding new
          innovative solutions this way. Programming skills: HTML5, CSS3,
          Javascript, Shopify Liquid
        </p>
        <p>
          My passion is art, design, architecture and music. I was born in
          Dachau near Munich and grew up in various places in Austria and
          Germany.
        </p>
        <p>
          After my apprenticeship as a carpenter and furniture designer, my
          passion for special, unusual and characterful design was not limited
          to the development of furniture. Therefore, in addition to graphic and
          web design, I also experimented with various other materials, in
          particular the experiments with textiles led to the idea at the end of
          2007 of founding my own fashion label and producing individual,
          sustainable clothing myself and selling it over the Internet.
        </p>
        <p>
          Here I developed a first graphic line and transferred the motifs to
          textiles using screen printing. These motifs hit the bull's eye and
          the very first ThokkThokk print motif “Squared” quickly became a
          bestseller, which was followed by many more.
        </p>
        <p>
          Over a period of more than ten years, these experiments with
          ThokkThokk developed into one of the most modern, creative and
          innovative sustainable fashion labels on the market and could do a lot
          of pioneering work for sustainable fashion.
        </p>
        <p>
          After more than ten years of hard development work and an ever growing
          business, the time had come for me to devote myself more to creativity
          and to work more freely. For this reason, I decided in 2019 to give
          ThokkThokk in different hands and to use my accumulated know-how from
          design and company structure for diverse creative projects.
        </p>
      </CatWrapper>
    </>
  )
}

export default Categories

export const pageQuery = graphql`
  query allHeroLinks {
    allPrismicHeroLinks {
      edges {
        node {
          id
        }
      }
    }
  }
`
